import React, { useState } from 'react';
import '../styles/travel.scss';
import {
  ShareAltOutlined,
  WarningOutlined,
  PhoneOutlined,
  LineOutlined,
  EnvironmentOutlined,
  AimOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Layout, Row, Col, Button, Drawer, Typography, Space, Divider } from 'antd';
import HeaderComponent from '../components/header/header';

const { Content, Footer } = Layout;

const { Text } = Typography;

const TravelPage = () => {

  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <Layout className="background-base">
      <HeaderComponent title="Empezar viaje" goTo="/order-details"/>
      <Content className="site-layout-background">
        <Divider />
        <p>Aquí va el mapa</p>
        <Divider />
      </Content>
      <Footer className="site-layout-background text-primary">
        <Content>
          <Drawer className="icon-color-primary"
            placement='bottom'
            closable={true}
            closeIcon={<LineOutlined/>}
            onClose={() => setShowDrawer(!showDrawer)}
            visible={showDrawer}
            mask={false}
          >
            <Row gutter={[0, 0]}>
              <Col span={2}><EnvironmentOutlined /></Col>
              <Col span={22}><Text className="text-primary">Ubicación en tiempo real</Text></Col>
            </Row>
            <Row gutter={[0, 8]} >
              <Col span={2}><AimOutlined /></Col>
              <Col span={22}><Text className="text-primary">Ubicación actual</Text></Col>
            </Row>
            <Row gutter={[0, 8]} >
              <Text className="font-sty">Lugares cercanos</Text>
            </Row>
            <Row>
              <Col span={2}><ShoppingCartOutlined /></Col>
              <Col span={22} className="text-primary"><Text>Mercado central</Text></Col>
            </Row>
          </Drawer>
        </Content>
        <Row align="middle" glutter={[0, 0]}>
          <Col flex={24}>
            <strong>ORIGEN:</strong> Loja - <strong>DESTINO:</strong> Cuenca
          </Col>
          <Col align="center" justify="center">
            <WarningOutlined size="large" />
            <p>&nbsp; SMS</p>
          </Col>
          <Col align="center" justify="center">
            <PhoneOutlined />
            <p> &nbsp; 911</p>
          </Col>
        </Row>
        <Space>
          <Col>
            <strong>TIEMPO</strong><br/>
            <strong>3.39h</strong>
          </Col>
          <Col>
            <strong>&nbsp; DISTANCIA</strong> <br/>
            <strong>&nbsp; 60Km</strong>
          </Col>
        </Space>
        <br />
        <Divider size="small" className="no-border" />
        <Row justify="space-around">
          <Col span={10}>
            <Button type="primary" size="large">
              Empezar Viaje
            </Button>
          </Col>
          <Col span={14}>
            <Button icon={<ShareAltOutlined />} size="large" onClick={() => setShowDrawer(!showDrawer)}>
              Compartir Ubicación
            </Button>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default TravelPage;
